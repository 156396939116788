import isMobile from "is-mobile";
import revive, { iddle } from "@/scripts/extra/revive";
import { add } from "@fluorescent/dom";

// Islands
const islands = {
  ...import.meta.glob('@/scripts/sections/*.js'),
  ...import.meta.glob('@/scripts/templates/*.js'),
  ...import.meta.glob('@/scripts/templates/**/*.js'),
  ...import.meta.glob('@/scripts/modules/*.js')
}

revive(islands)

async function loadLibraries() {
  await iddle()

  import('@/scripts/extra/load-libraries.js')
}

loadLibraries()

// eslint-disable-next-line no-prototype-builtins
if (!HTMLElement.prototype.hasOwnProperty("inert")) {
  import( /* @vite-ignore */ flu.chunks.polyfillInert);
}

if (isMobile({ tablet: true, featureDetect: true })) {
  const isIosDevice = typeof window !== 'undefined' && window.navigator && window.navigator.platform && (/iP(ad|hone|od)/.test(window.navigator.platform) || window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);
  if (isIosDevice) {
    add(document.body, "is-ios-device")
  } else {
    add(document.body, "is-mobile")
  }
}

window.addEventListener('scroll', () => {
  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    document.body.classList.add('is-end-scroll')
  } else {
    document.body.classList.remove('is-end-scroll')
  }
})
